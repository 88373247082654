<template>
  <v-app>
    <app-container class="d-flex h-100 align-center justify-center">
      <div class="text-center">
        <p class="text-h6 text-primary">404</p>
        <h1>Page not found</h1>
        <p class="text-medium-emphasis mb-4">
          The awesome page you're looking for isn't here.
        </p>
        <app-home-btn class="mt-4" @click="handleError"></app-home-btn>
      </div>
    </app-container>
  </v-app>
</template>

<script setup>
defineProps({
  error: Object,
})
const handleError = () => clearError({ redirect: '/' })

import { useTheme } from 'vuetify'
const theme = useTheme()
const colorMode = useColorMode()

const cookieColorMode = useCookie('color-mode', {
  maxAge: 31536000,
  watch: true,
})
const cookieColorModePreference = useCookie('color-mode-preference', {
  maxAge: 31536000,
  watch: true,
})

if (cookieColorMode) {
  colorMode.value = cookieColorMode.value
}

cookieColorMode.value = colorMode.value
cookieColorModePreference.value = colorMode.preference

watch(
  () => colorMode.preference,
  (val) => {
    cookieColorModePreference.value = val
  }
)

watch(
  () => colorMode.value,
  (val) => {
    cookieColorMode.value = val
    // this is needed, if you reload with clear cache the cards would be white, dunno why
    setTimeout(() => {
      theme.global.name.value = val
    })
  }
)

// I cannot explain this, but in local it stops flickering
if (process.server) {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  } else {
    theme.global.name.value = 'light'
  }
}

onMounted(() => {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  }
})
</script>

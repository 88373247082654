<template>
  <v-col v-for="{ href, icon } in socialLinks" :key="href" cols="auto">
    <v-btn
      rel="noopener noopener noreferrer"
      :aria-label="href"
      :href="href"
      target="blank"
      :icon="icon"
      variant="text"
      size="small"
    >
    </v-btn>
  </v-col>
</template>

<script setup>
import { mdiLinkedin, mdiTwitter, mdiFacebook, mdiInstagram } from '@mdi/js'
const socialLinks = [
  {
    href: 'https://twitter.com/awesotechstack',
    icon: mdiTwitter,
  },
  {
    href: 'https://www.linkedin.com/company/awesometechstack/',
    icon: mdiLinkedin,
  },
  {
    href: 'https://www.instagram.com/awesometechstack/',
    icon: mdiInstagram,
  },
  {
    href: 'https://www.facebook.com/people/AwesometechStack/100088674737136/',
    icon: mdiFacebook,
  },
]
</script>

<template>
  <v-btn
    v-show="
      route.name !== 'user-subscription' &&
      store.user &&
      store.user.plan === 'free'
    "
    variant="outlined"
    :to="{ name: 'user-subscription' }"
    :prepend-icon="mdiRocketLaunch"
  >
    Select Plan
  </v-btn>
</template>

<script setup>
import { mdiRocketLaunch } from '@mdi/js'
import { useStore } from '@/composables/store'
const store = useStore()
const route = useRoute()
</script>

import { default as aboutKgHt9qCcRWMeta } from "/workspace/run/nuxt-service/pages/about.vue?macro=true";
import { default as _91slug_93aiVcUu3FfVMeta } from "/workspace/run/nuxt-service/pages/analysis/website/[slug].vue?macro=true";
import { default as remove2T3zA6LNwLMeta } from "/workspace/run/nuxt-service/pages/analysis/website/remove.vue?macro=true";
import { default as lighthouse0LqGR9VtkHMeta } from "/workspace/run/nuxt-service/pages/analysis/website/reports/lighthouse.vue?macro=true";
import { default as contactudej3d4c2wMeta } from "/workspace/run/nuxt-service/pages/contact.vue?macro=true";
import { default as _91slug_93VPSLdLA28cMeta } from "/workspace/run/nuxt-service/pages/content/articles/[slug].vue?macro=true";
import { default as indexydUaP3oKmmMeta } from "/workspace/run/nuxt-service/pages/content/articles/index.vue?macro=true";
import { default as faqoFG2poIUaPMeta } from "/workspace/run/nuxt-service/pages/faq.vue?macro=true";
import { default as imprintGa9i6rey5BMeta } from "/workspace/run/nuxt-service/pages/imprint.vue?macro=true";
import { default as index8exmaUqUtPMeta } from "/workspace/run/nuxt-service/pages/index.vue?macro=true";
import { default as awesomedO6jhcb3YiMeta } from "/workspace/run/nuxt-service/pages/insights/technologies/awesome.vue?macro=true";
import { default as most_45usedqCRzNy1I2CMeta } from "/workspace/run/nuxt-service/pages/insights/technologies/most-used.vue?macro=true";
import { default as awesome5FiGQNcCKTMeta } from "/workspace/run/nuxt-service/pages/insights/websites/awesome.vue?macro=true";
import { default as recently_45analyzedvBIMxERIlYMeta } from "/workspace/run/nuxt-service/pages/insights/websites/recently-analyzed.vue?macro=true";
import { default as top_45tech_45stackzpcwyT17I9Meta } from "/workspace/run/nuxt-service/pages/insights/websites/top-tech-stack.vue?macro=true";
import { default as pricingHA501CoyfJMeta } from "/workspace/run/nuxt-service/pages/pricing.vue?macro=true";
import { default as privacySWRgK8Y3KNMeta } from "/workspace/run/nuxt-service/pages/privacy.vue?macro=true";
import { default as docsoQnSSvQgzzMeta } from "/workspace/run/nuxt-service/pages/products/website-analyzer/docs.vue?macro=true";
import { default as indexisa4ieKe9nMeta } from "/workspace/run/nuxt-service/pages/products/website-analyzer/index.vue?macro=true";
import { default as _91slug_93xMkc43g9UsMeta } from "/workspace/run/nuxt-service/pages/technologies/[category]/[slug].vue?macro=true";
import { default as _91slug_93FEI0YcvcXFMeta } from "/workspace/run/nuxt-service/pages/technologies/[slug].vue?macro=true";
import { default as indexnjkGcHlOJBMeta } from "/workspace/run/nuxt-service/pages/technologies/index.vue?macro=true";
import { default as terms0Uph9cJhKBMeta } from "/workspace/run/nuxt-service/pages/terms.vue?macro=true";
import { default as accountTN8c6PVLg1Meta } from "/workspace/run/nuxt-service/pages/user/account.vue?macro=true";
import { default as api_45keysZwcWNRaeBsMeta } from "/workspace/run/nuxt-service/pages/user/api-keys.vue?macro=true";
import { default as deletedrmgRpIr1lvMeta } from "/workspace/run/nuxt-service/pages/user/deleted.vue?macro=true";
import { default as historyQYoJM297kFMeta } from "/workspace/run/nuxt-service/pages/user/history.vue?macro=true";
import { default as invoiceNnagtf4pIWMeta } from "/workspace/run/nuxt-service/pages/user/invoice.vue?macro=true";
import { default as payment_45methodstalmV9fzmMMeta } from "/workspace/run/nuxt-service/pages/user/payment-methods.vue?macro=true";
import { default as stories3kxlcbzNlUMeta } from "/workspace/run/nuxt-service/pages/user/stories.vue?macro=true";
import { default as subscription15PDp5ct3NMeta } from "/workspace/run/nuxt-service/pages/user/subscription.vue?macro=true";
import { default as webhook_45endpointszf7NxO14XIMeta } from "/workspace/run/nuxt-service/pages/user/webhook-endpoints.vue?macro=true";
import { default as whyIsr1Z6FHpMMeta } from "/workspace/run/nuxt-service/pages/why.vue?macro=true";
export default [
  {
    name: aboutKgHt9qCcRWMeta?.name ?? "about",
    path: aboutKgHt9qCcRWMeta?.path ?? "/about",
    meta: aboutKgHt9qCcRWMeta || {},
    alias: aboutKgHt9qCcRWMeta?.alias || [],
    redirect: aboutKgHt9qCcRWMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aiVcUu3FfVMeta?.name ?? "analysis-website-slug",
    path: _91slug_93aiVcUu3FfVMeta?.path ?? "/analysis/website/:slug()",
    meta: _91slug_93aiVcUu3FfVMeta || {},
    alias: _91slug_93aiVcUu3FfVMeta?.alias || [],
    redirect: _91slug_93aiVcUu3FfVMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/analysis/website/[slug].vue").then(m => m.default || m)
  },
  {
    name: remove2T3zA6LNwLMeta?.name ?? "analysis-website-remove",
    path: remove2T3zA6LNwLMeta?.path ?? "/analysis/website/remove",
    meta: remove2T3zA6LNwLMeta || {},
    alias: remove2T3zA6LNwLMeta?.alias || [],
    redirect: remove2T3zA6LNwLMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/analysis/website/remove.vue").then(m => m.default || m)
  },
  {
    name: lighthouse0LqGR9VtkHMeta?.name ?? "analysis-website-reports-lighthouse",
    path: lighthouse0LqGR9VtkHMeta?.path ?? "/analysis/website/reports/lighthouse",
    meta: lighthouse0LqGR9VtkHMeta || {},
    alias: lighthouse0LqGR9VtkHMeta?.alias || [],
    redirect: lighthouse0LqGR9VtkHMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/analysis/website/reports/lighthouse.vue").then(m => m.default || m)
  },
  {
    name: contactudej3d4c2wMeta?.name ?? "contact",
    path: contactudej3d4c2wMeta?.path ?? "/contact",
    meta: contactudej3d4c2wMeta || {},
    alias: contactudej3d4c2wMeta?.alias || [],
    redirect: contactudej3d4c2wMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VPSLdLA28cMeta?.name ?? "content-articles-slug",
    path: _91slug_93VPSLdLA28cMeta?.path ?? "/content/articles/:slug()",
    meta: _91slug_93VPSLdLA28cMeta || {},
    alias: _91slug_93VPSLdLA28cMeta?.alias || [],
    redirect: _91slug_93VPSLdLA28cMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/content/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexydUaP3oKmmMeta?.name ?? "content-articles",
    path: indexydUaP3oKmmMeta?.path ?? "/content/articles",
    meta: indexydUaP3oKmmMeta || {},
    alias: indexydUaP3oKmmMeta?.alias || [],
    redirect: indexydUaP3oKmmMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/content/articles/index.vue").then(m => m.default || m)
  },
  {
    name: faqoFG2poIUaPMeta?.name ?? "faq",
    path: faqoFG2poIUaPMeta?.path ?? "/faq",
    meta: faqoFG2poIUaPMeta || {},
    alias: faqoFG2poIUaPMeta?.alias || [],
    redirect: faqoFG2poIUaPMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: imprintGa9i6rey5BMeta?.name ?? "imprint",
    path: imprintGa9i6rey5BMeta?.path ?? "/imprint",
    meta: imprintGa9i6rey5BMeta || {},
    alias: imprintGa9i6rey5BMeta?.alias || [],
    redirect: imprintGa9i6rey5BMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: index8exmaUqUtPMeta?.name ?? "index",
    path: index8exmaUqUtPMeta?.path ?? "/",
    meta: index8exmaUqUtPMeta || {},
    alias: index8exmaUqUtPMeta?.alias || [],
    redirect: index8exmaUqUtPMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/index.vue").then(m => m.default || m)
  },
  {
    name: awesomedO6jhcb3YiMeta?.name ?? "insights-technologies-awesome",
    path: awesomedO6jhcb3YiMeta?.path ?? "/insights/technologies/awesome",
    meta: awesomedO6jhcb3YiMeta || {},
    alias: awesomedO6jhcb3YiMeta?.alias || [],
    redirect: awesomedO6jhcb3YiMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/insights/technologies/awesome.vue").then(m => m.default || m)
  },
  {
    name: most_45usedqCRzNy1I2CMeta?.name ?? "insights-technologies-most-used",
    path: most_45usedqCRzNy1I2CMeta?.path ?? "/insights/technologies/most-used",
    meta: most_45usedqCRzNy1I2CMeta || {},
    alias: most_45usedqCRzNy1I2CMeta?.alias || [],
    redirect: most_45usedqCRzNy1I2CMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/insights/technologies/most-used.vue").then(m => m.default || m)
  },
  {
    name: awesome5FiGQNcCKTMeta?.name ?? "insights-websites-awesome",
    path: awesome5FiGQNcCKTMeta?.path ?? "/insights/websites/awesome",
    meta: awesome5FiGQNcCKTMeta || {},
    alias: awesome5FiGQNcCKTMeta?.alias || [],
    redirect: awesome5FiGQNcCKTMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/insights/websites/awesome.vue").then(m => m.default || m)
  },
  {
    name: recently_45analyzedvBIMxERIlYMeta?.name ?? "insights-websites-recently-analyzed",
    path: recently_45analyzedvBIMxERIlYMeta?.path ?? "/insights/websites/recently-analyzed",
    meta: recently_45analyzedvBIMxERIlYMeta || {},
    alias: recently_45analyzedvBIMxERIlYMeta?.alias || [],
    redirect: recently_45analyzedvBIMxERIlYMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/insights/websites/recently-analyzed.vue").then(m => m.default || m)
  },
  {
    name: top_45tech_45stackzpcwyT17I9Meta?.name ?? "insights-websites-top-tech-stack",
    path: top_45tech_45stackzpcwyT17I9Meta?.path ?? "/insights/websites/top-tech-stack",
    meta: top_45tech_45stackzpcwyT17I9Meta || {},
    alias: top_45tech_45stackzpcwyT17I9Meta?.alias || [],
    redirect: top_45tech_45stackzpcwyT17I9Meta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/insights/websites/top-tech-stack.vue").then(m => m.default || m)
  },
  {
    name: pricingHA501CoyfJMeta?.name ?? "pricing",
    path: pricingHA501CoyfJMeta?.path ?? "/pricing",
    meta: pricingHA501CoyfJMeta || {},
    alias: pricingHA501CoyfJMeta?.alias || [],
    redirect: pricingHA501CoyfJMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacySWRgK8Y3KNMeta?.name ?? "privacy",
    path: privacySWRgK8Y3KNMeta?.path ?? "/privacy",
    meta: privacySWRgK8Y3KNMeta || {},
    alias: privacySWRgK8Y3KNMeta?.alias || [],
    redirect: privacySWRgK8Y3KNMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: docsoQnSSvQgzzMeta?.name ?? "products-website-analyzer-docs",
    path: docsoQnSSvQgzzMeta?.path ?? "/products/website-analyzer/docs",
    meta: docsoQnSSvQgzzMeta || {},
    alias: docsoQnSSvQgzzMeta?.alias || [],
    redirect: docsoQnSSvQgzzMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/products/website-analyzer/docs.vue").then(m => m.default || m)
  },
  {
    name: indexisa4ieKe9nMeta?.name ?? "products-website-analyzer",
    path: indexisa4ieKe9nMeta?.path ?? "/products/website-analyzer",
    meta: indexisa4ieKe9nMeta || {},
    alias: indexisa4ieKe9nMeta?.alias || [],
    redirect: indexisa4ieKe9nMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/products/website-analyzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xMkc43g9UsMeta?.name ?? "technologies-category-slug",
    path: _91slug_93xMkc43g9UsMeta?.path ?? "/technologies/:category()/:slug()",
    meta: _91slug_93xMkc43g9UsMeta || {},
    alias: _91slug_93xMkc43g9UsMeta?.alias || [],
    redirect: _91slug_93xMkc43g9UsMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/technologies/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FEI0YcvcXFMeta?.name ?? "technologies-slug",
    path: _91slug_93FEI0YcvcXFMeta?.path ?? "/technologies/:slug()",
    meta: _91slug_93FEI0YcvcXFMeta || {},
    alias: _91slug_93FEI0YcvcXFMeta?.alias || [],
    redirect: _91slug_93FEI0YcvcXFMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/technologies/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnjkGcHlOJBMeta?.name ?? "technologies",
    path: indexnjkGcHlOJBMeta?.path ?? "/technologies",
    meta: indexnjkGcHlOJBMeta || {},
    alias: indexnjkGcHlOJBMeta?.alias || [],
    redirect: indexnjkGcHlOJBMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/technologies/index.vue").then(m => m.default || m)
  },
  {
    name: terms0Uph9cJhKBMeta?.name ?? "terms",
    path: terms0Uph9cJhKBMeta?.path ?? "/terms",
    meta: terms0Uph9cJhKBMeta || {},
    alias: terms0Uph9cJhKBMeta?.alias || [],
    redirect: terms0Uph9cJhKBMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: accountTN8c6PVLg1Meta?.name ?? "user-account",
    path: accountTN8c6PVLg1Meta?.path ?? "/user/account",
    meta: accountTN8c6PVLg1Meta || {},
    alias: accountTN8c6PVLg1Meta?.alias || [],
    redirect: accountTN8c6PVLg1Meta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/account.vue").then(m => m.default || m)
  },
  {
    name: api_45keysZwcWNRaeBsMeta?.name ?? "user-api-keys",
    path: api_45keysZwcWNRaeBsMeta?.path ?? "/user/api-keys",
    meta: api_45keysZwcWNRaeBsMeta || {},
    alias: api_45keysZwcWNRaeBsMeta?.alias || [],
    redirect: api_45keysZwcWNRaeBsMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/api-keys.vue").then(m => m.default || m)
  },
  {
    name: deletedrmgRpIr1lvMeta?.name ?? "user-deleted",
    path: deletedrmgRpIr1lvMeta?.path ?? "/user/deleted",
    meta: deletedrmgRpIr1lvMeta || {},
    alias: deletedrmgRpIr1lvMeta?.alias || [],
    redirect: deletedrmgRpIr1lvMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/deleted.vue").then(m => m.default || m)
  },
  {
    name: historyQYoJM297kFMeta?.name ?? "user-history",
    path: historyQYoJM297kFMeta?.path ?? "/user/history",
    meta: historyQYoJM297kFMeta || {},
    alias: historyQYoJM297kFMeta?.alias || [],
    redirect: historyQYoJM297kFMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/history.vue").then(m => m.default || m)
  },
  {
    name: invoiceNnagtf4pIWMeta?.name ?? "user-invoice",
    path: invoiceNnagtf4pIWMeta?.path ?? "/user/invoice",
    meta: invoiceNnagtf4pIWMeta || {},
    alias: invoiceNnagtf4pIWMeta?.alias || [],
    redirect: invoiceNnagtf4pIWMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/invoice.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodstalmV9fzmMMeta?.name ?? "user-payment-methods",
    path: payment_45methodstalmV9fzmMMeta?.path ?? "/user/payment-methods",
    meta: payment_45methodstalmV9fzmMMeta || {},
    alias: payment_45methodstalmV9fzmMMeta?.alias || [],
    redirect: payment_45methodstalmV9fzmMMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: stories3kxlcbzNlUMeta?.name ?? "user-stories",
    path: stories3kxlcbzNlUMeta?.path ?? "/user/stories",
    meta: stories3kxlcbzNlUMeta || {},
    alias: stories3kxlcbzNlUMeta?.alias || [],
    redirect: stories3kxlcbzNlUMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/stories.vue").then(m => m.default || m)
  },
  {
    name: subscription15PDp5ct3NMeta?.name ?? "user-subscription",
    path: subscription15PDp5ct3NMeta?.path ?? "/user/subscription",
    meta: subscription15PDp5ct3NMeta || {},
    alias: subscription15PDp5ct3NMeta?.alias || [],
    redirect: subscription15PDp5ct3NMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/subscription.vue").then(m => m.default || m)
  },
  {
    name: webhook_45endpointszf7NxO14XIMeta?.name ?? "user-webhook-endpoints",
    path: webhook_45endpointszf7NxO14XIMeta?.path ?? "/user/webhook-endpoints",
    meta: webhook_45endpointszf7NxO14XIMeta || {},
    alias: webhook_45endpointszf7NxO14XIMeta?.alias || [],
    redirect: webhook_45endpointszf7NxO14XIMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/user/webhook-endpoints.vue").then(m => m.default || m)
  },
  {
    name: whyIsr1Z6FHpMMeta?.name ?? "why",
    path: whyIsr1Z6FHpMMeta?.path ?? "/why",
    meta: whyIsr1Z6FHpMMeta || {},
    alias: whyIsr1Z6FHpMMeta?.alias || [],
    redirect: whyIsr1Z6FHpMMeta?.redirect,
    component: () => import("/workspace/run/nuxt-service/pages/why.vue").then(m => m.default || m)
  }
]
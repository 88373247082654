<template>
  <!--<ColorScheme>-->
  <v-app>
    <!--<NuxtPwaManifest />-->
    <NuxtLoadingIndicator :color="color" />
    <CookieControl></CookieControl>
    <AppBar />
    <AppNavigationDrawer />
    <v-main>
      <NuxtPage />
    </v-main>
    <v-dialog v-model="showSignInSignUpDialog" persistent max-width="400">
      <LazySignInSignUpCard
        :standalone="false"
        @close="showSignInSignUpDialog = false"
        v-if="showSignInSignUpDialog"
      ></LazySignInSignUpCard>
    </v-dialog>
    <AppFooter />
    <LazyAppSnackbar
      v-model="store.analyzeCanceled"
      :text="`Canceled analyzing of ${store.analyzeCanceledUrl}. Check back later to see your analysis results`"
    >
    </LazyAppSnackbar>
    <AdsCarbonInit></AdsCarbonInit>
  </v-app>
  <!--</ColorScheme>-->
</template>

<script setup>
import { joinURL } from 'ufo'
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { useStore } from '@/composables/store'

import { useTheme } from 'vuetify'
const theme = useTheme()

const { grantConsent, revokeConsent } = useGtag()
const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions,
} = useCookieControl()

const store = useStore()
const { $auth } = useNuxtApp()

const showSignInSignUpDialog = computed({
  get() {
    return (
      store.showSignInSignUpDialog === 'signin' ||
      store.showSignInSignUpDialog === 'signup'
    )
  },
  set(val) {
    if (!val) {
      store.hideSignInSignUpDialog()
    }
  },
})

const color = '#fc619d'

useHead({
  titleTemplate: (title) =>
    title
      ? `${title} - AwesomeTechStack`
      : 'AwesomeTechStack: Website Tech Stack Analysis',
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
  ],
  link: [{ rel: 'icon', type: 'image/png', href: '/icon.png' }],
  htmlAttrs: {
    lang: 'en',
  },
})

const site = useSiteConfig()
useSeoMeta({
  ogSiteName: 'AwesomeTechStack',
  ogType: 'website',
  twitterCard: 'summary_large_image',
  twitterSite: 'awesotechstack',
  ogImage: joinURL(site.url, '/AwesomeTechStack.png'),
  ogImageType: 'image/png',
  twitterImage: joinURL(site.url, '/AwesomeTechStack.png'),
})

onMounted(() => {
  onAuthStateChanged($auth, (user) => {
    store.$patch({
      user: user,
    })
    store.setUserLoading(false)
    if (user) {
      store.hideSignInSignUpDialog()
    }
    store.onAuthStateChangedAction()
  })

  onIdTokenChanged($auth, (user) => {
    store.$patch({
      user: user,
    })
    store.onIdTokenChangedAction()
  })
})

watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (
      !previous?.includes('google-analytics') &&
      current?.includes('google-analytics')
    ) {
      grantConsent()
    } else {
      revokeConsent()
    }
  },
  { deep: true }
)

/*export default {
  head() {
    const { path } = this.$route;
    const pathWithSlash = path.endsWith("/") ? path : `${path}/`;
    const canonical = `${this.$config.BASE_URL}${pathWithSlash}`;
    return {
      link: [{ rel: "canonical", href: canonical }],
    };
  },
  computed: {
    userSignOutTriggered: {
      get() {
        return this.$store.state.userSignOutTriggered;
      },
      set(val) {
        this.$store.commit("SET_USER_SIGN_OUT_TRIGGERED", val);
      },
    },
    analyzeCanceled: {
      get() {
        return this.$store.state.analyzeCanceled;
      },
      set(val) {
        this.$store.commit("ANALYZE_CANCELED", { url: "" });
      },
    },
    showSignInSignUpDialog: {
      get() {
        return (
          this.$store.state.showSignInSignUpDialog === "signin" ||
          this.$store.state.showSignInSignUpDialog === "signup"
        );
      },
      set(val) {
        if (!val) {
          this.$store.commit("HIDE_SIGN_IN_SIGN_UP_DIALOG");
        }
      },
    },
  },
};*/

const colorMode = useColorMode()

const cookieColorMode = useCookie('color-mode', {
  maxAge: 31536000,
  watch: true,
})
const cookieColorModePreference = useCookie('color-mode-preference', {
  maxAge: 31536000,
  watch: true,
})

if (cookieColorMode) {
  colorMode.value = cookieColorMode.value
}

cookieColorMode.value = colorMode.value
cookieColorModePreference.value = colorMode.preference

watch(
  () => colorMode.preference,
  (val) => {
    cookieColorModePreference.value = val
  }
)

watch(
  () => colorMode.value,
  (val) => {
    cookieColorMode.value = val
    // this is needed, if you reload with clear cache the cards would be white, dunno why
    setTimeout(() => {
      theme.global.name.value = val
    })
  }
)

// I cannot explain this, but in local it stops flickering
if (process.server) {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  } else {
    theme.global.name.value = 'light'
  }
}

onMounted(() => {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  }
})
</script>


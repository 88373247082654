<template>
  <v-app-bar :height="64" image="" flat class="border-b app-bar">
    <template v-slot:title>
      <app-logo />
    </template>

    <template v-slot:image>
      <v-img alt="app-bar-background" cover src="/img/app-bar-bg.png"></v-img>
    </template>

    <v-spacer />
    <div class="d-none d-lg-block align-center text-truncate">
      <template v-for="item in store.appBarNavigation">
        <v-btn
          v-if="!item.links || item.to"
          :key="item.text"
          :variant="item.outlined ? 'outlined' : 'text'"
          :to="item.to ? item.to : undefined"
          @click="onClick(item)"
        >
          {{ item.text }}
        </v-btn>

        <v-menu v-else :key="`menu-${item.text}`" bottom offset-y centered>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">
              {{ item.text }}
              <v-icon size="small" end>{{ mdiChevronDown }}</v-icon>
            </v-btn>
          </template>

          <v-list nav role="menu">
            <v-list-subheader v-if="item.showHeader">{{
              item.text
            }}</v-list-subheader>
            <template v-for="(link, index) in item.links">
              <v-list-subheader
                :key="`appbar-subheader-${index}`"
                v-if="link.isSubheader"
                >{{ link.text }}</v-list-subheader
              >
              <v-list-item
                v-else
                :key="`appbar-link-${index}`"
                role="menuitem"
                :title="link.text"
                :subtitle="link.description"
                :to="link.to"
              >
                <template v-slot:prepend>
                  <app-icon
                    class="mr-4"
                    v-if="link.appIcon"
                    :size="46"
                  ></app-icon>
                  <v-icon v-else-if="link.icon">{{ link.icon }}</v-icon>
                </template>
                <template v-slot:title>
                  <div v-html="link.text"></div>
                </template>
                <template v-slot:subtitle>
                  <div v-html="link.description"></div>
                </template>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <!-- authentication -->
      <sign-in-btn text></sign-in-btn>
      <sign-up-btn outlined text></sign-up-btn>

      <user-menu v-if="store.isAuthenticated || store.userLoading"></user-menu>
    </div>
    <template v-slot:append>
      <color-mode-btn></color-mode-btn>
      <v-app-bar-nav-icon
        class="d-block d-lg-none"
        aria-label="Menu"
        @click.stop="toggleDrawer"
      />
    </template>
  </v-app-bar>
</template>

<script setup>
import { useStore } from '@/composables/store'
import {
  mdiChevronDown,
  mdiAccount,
  mdiPost,
  mdiChevronRight,
  mdiNewBox,
} from '@mdi/js'

const store = useStore()

const drawer = computed({
  get() {
    return store.drawer
  },
  set(val) {
    store.setDrawer(val)
  },
})

const onClick = (item) => {
  if (item.action) {
    store[item.action]()
  }
}

const toggleDrawer = () => {
  drawer.value = !drawer.value
}
</script>
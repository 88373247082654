<template>
  <v-responsive class="mx-auto">
    <section>
      <v-row align="start" justify="space-between">
        <v-col
          v-for="item in store.footerNavigation"
          :key="item.text"
          cols="12"
          :md="12 / store.footerNavigation.length"
        >
          <v-list nav bg-color="transparent" subheader class="pa-0 transparent">
            <v-list-subheader tag="h4">{{ item.text }}</v-list-subheader>
            <v-list-item
              v-for="link in item.links"
              :key="link.text"
              dense
              :to="link.to"
              :title="link.text"
            >
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </section>
  </v-responsive>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>

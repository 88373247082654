<template>
  <div>
    <v-divider></v-divider>
    <template v-if="route.name !== 'contact'">
      <app-contact-us class="py-4 py-sm-8"></app-contact-us>
      <v-divider></v-divider>
    </template>
    <v-container>
      <app-footer-navigation />
    </v-container>
    <v-divider></v-divider>
    <v-footer tile padless>
      <v-container fluid>
        <v-row
          class="text-center"
          justify-md="space-between"
          align="center"
          justify="center"
        >
          <v-col cols="12" md="auto">
            <div class="d-flex align-center justify-center">
              <app-icon class="ml-2 mr-2" size="20"></app-icon>
              © {{ new Date().getFullYear() }} - AwesomeTechStack
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="auto">
            <v-row dense justify="center" align="center">
              <social-links></social-links>
              <v-col cols="auto">
                <v-btn
                  rel="noopener noreferrer"
                  aria-label="buymeacoffee.com"
                  href="https://www.buymeacoffee.com/Zi5caV7"
                  target="_blank"
                  icon
                  variant="text"
                  size="small"
                >
                  <NuxtImg
                    loading="lazy"
                    :width="20"
                    :height="20"
                    quality="70"
                    src="img/ads/buyMeACoffe.png"
                    alt="buyMeACoffe"
                  ></NuxtImg>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  rel="noopener noreferrer"
                  aria-label="ko-fi.com"
                  href="https://ko-fi.com/I2I42S8A4"
                  target="_blank"
                  icon
                  variant="text"
                  size="small"
                >
                  <NuxtImg
                    fit="contain"
                    loading="lazy"
                    :width="20"
                    :height="20"
                    quality="100"
                    src="img/ads/koFi.png"
                    alt="koFi"
                  ></NuxtImg>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="auto">
            <app-footer-btn
              v-for="i in 4"
              :key="`app-footer-links${i + 1}`"
              :text="$t(`AppFooter.links.${i + 1}.text`)"
              :to="{
                name: $t(`AppFooter.links.${i + 1}.to.name`),
              }"
            ></app-footer-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script setup>
const route = useRoute()
</script>

